@import '/src/scss/vars';

.subscribe-section {
  &__inner {
    display: grid;
    gap: var(--spacing3);
    padding: var(--spacing5);
    border-radius: 16px;
    background: var(--color4);
  }
  
  &__title {
    margin-bottom: var(--spacing3);
  }

  &__info {
    display: block;
    color: var(--color3);
  }

}

@media (min-width: $tablet2) {

  .subscribe-section {
    padding-top: 16px;

    &__inner {
      position: relative;
      grid-template-columns: 1fr 1fr;
      gap: var(--spacing8);
      padding: 60px;
      &:after {
        content: '';
        position: absolute;
        bottom: -60px;
        left: -25px;
        width: 148px;
        height: 91px;
        background: url($path + 'decor2.svg') center/contain no-repeat;
      }
    }

    &__title {
      font-size: 44px;
      line-height: 51px;
      margin-bottom: 0;
    }

  }

}

@media (min-width: $desktop) {

  .subscribe-section {

    &__inner {
      grid-template-columns: 1fr 520px;
    }
    &__title {
      font-size: 72px;
      line-height: 79px;
    }

  }

}