@import '/src/scss/vars';



.comment-section {
  
  &__items {
    display: none;
  }

}

@media (min-width: $mob) {

  .comment-section {
    
    &__slider {
      display: none;
    }

    &__items {
      display: grid;
      gap: var(--spacing4);
      grid-template-columns: repeat( auto-fit, minmax(288px, 1fr) );
    }

  }

}