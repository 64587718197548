@import '/src/scss/vars';


.episode-section {
  &__wrap-title {
    margin-bottom: var(--spacing8);
  }
  &__items {
    display: grid;
    gap: var(--spacing6);
  }
  &__button {
    display: flex;
    margin: var(--spacing7) auto 0;
    padding: var(--spacing4) var(--spacing9);
  }
}

@media (min-width: $tablet) {
  .episode-section {
    &__items {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (min-width: $laptop) {
  .episode-section {
    &__items {
      grid-template-columns: 1fr;
      gap: 60px;
    }
    
    &__button {
      margin-top: var(--spacing9);
    }
  }
}