@import '/src/scss/vars';

.button-menu {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer; 

  &__line {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: inherit;
    height: 2.6px;
    background: var(--color2);
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: inherit;
      width: inherit;
      height: inherit;
      background: var(--color2);
      transition: var(--transition);
    }
    &:before {
      top: -10px;
    }
    &:after {
      bottom: -10px;
    }
  }

  &_active .button-menu__line {
    background: 0;
  }

  &_active .button-menu__line:before {
    top: 0;
    transform: rotate(45deg); 
  }
  
  &_active .button-menu__line:after {
    bottom: 0;
    transform: rotate(-45deg); 
  }

}