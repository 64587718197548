@import '/src/scss/vars';

.logo {
  width: 120px;
  height: 40px;
}

@media (min-width: $desktop) {
  .logo {
    width: 240px;
    height: 80px;
  }
}