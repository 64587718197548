@import '/src/scss/vars';


.intro-section {
  
  &__row {
    display: grid;
    place-items: center;
  }
  
  &__wrap-img {
    position: relative;
    width: calc(100% + var(--spacing4) * 2);
  }
  
  &__wrap-img:before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,.42);
  }
  
  &__img {
    display: block;
    height: calc(100vh - 78px);
    width: 100%;
    margin-inline: auto;
    object-fit: cover;
  }
  
  &__wrap-img,
  &__content {
    grid-column: 1/-1;
    grid-row: 1/-1;
  }
  
  &__content {
    position: relative;
    max-width: 400px;
  }
  
  &__title {
    margin-bottom: var(--spacing3);
  }
  
  &__social {
    display: grid;
    justify-content: center;
    gap: var(--spacing3);
    margin-top: var(--spacing6);
  }

}

@media (min-width: $laptop) {

  .intro-section {
    padding-top: 16px;

    &__row {
      grid-template-columns: 450px 1fr;
      gap: var(--spacing8);
    }

    &__wrap-img,
    &__content {
      grid-column: unset;
      grid-row: unset;
    }

    &__wrap-img:before {
      top: -26px;
      left: -30px;
      width: 68px;
      height: 74px;
      background: url($path + 'decor.svg') center/contain no-repeat;
    }

    &__wrap-img {
      width: 100%;
    }

    &__img {
      height: calc(100vh - 126px);
    }

    &__content {
      max-width: unset;
    }

    &__social {
      justify-content: flex-start;
      margin-top: var(--spacing9);
    }

  }

}

@media (min-width: $desktop) {
  .intro-section {
    &__row {
      grid-template-columns: 650px 1fr;
    }
  }
}