@import '/src/scss/vars';

.box {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing2);
  background: var(--color4);
  border-radius: var(--borderRadius2);
}

.img {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing4);
  padding: var(--spacing4);
}
.title {
  font-size: 28px;
  line-height: 35px;
}
.text {
  display: block;
}
.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: var(--spacing1) var(--spacing2);
  background: var(--color1);
  border-radius: var(--borderRadius2);
}
.info {
  display: block;
  color: var(--color3);
}
.button {
  margin-top: auto;
}
@media (min-width: $laptop) {
  .box {
    flex-wrap: nowrap;
    gap: 50px;
    padding: 40px;
    border-radius: 32px;
  }
  .content {
    padding: 0;
  }
  .img {
    flex-shrink: 0;
    width: 390px;
    height: 350px;
  }
  .button {
    width: fit-content;
  }
}