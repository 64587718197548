@import '/src/scss/vars';


.modal-window {
  position: fixed;
  z-index: -1;
  display: grid;
  place-items: center;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  background: rgba(0,0,0,.6);
  transition: .2s;
}

.modal-window__content  {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing4);
  max-width: 400px;
  width: 100%;
  padding: var(--spacing7) var(--spacing5);
  color: var(--color1);
  background: var(--color2);
  border-radius: var(--borderRadius2);
  transition: var(--transition);
  cursor: initial;
}

.modal-window__title {
  font-size: var(--h1);
  line-height: var(--h1-lh);
  font-weight: 600;
}

.modal-window_active {
  inset: 0;
  z-index: 10;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

.opened-modal {
  overflow: hidden;
}
@-moz-document url-prefix() {
  .opened-modal .root {
    padding-right: 6px;
  }
}
@media (min-width: $laptop) {
  .opened-modal .root {
    padding-right: 10px;
  }
  .opened-modal .header {
    left: -4px;
  }
}

.cross {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.cross__line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 2px;
  pointer-events: none;
}
.cross__line:before,
.cross__line:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  width: 100%;
  height: 2px;
  background: #696685;
  transition: ease 0.3s;
}
.cross__line:after {
  transform: rotate(-45deg);
}
.cross__line_minus:before {
  opacity: 0;
}
.cross_plus {
  transform: rotate(45deg);
}