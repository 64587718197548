@import '/src/scss/vars';
@import 'swiper/scss';
@import 'swiper/scss/pagination';

/* =========== common ============ */

:root {
  --cnt-width: 750px;

  --spacing1: 4px;
  --spacing2: 8px;
  --spacing3: 12px;
  --spacing4: 16px;
  --spacing5: 24px;
  --spacing6: 32px;
  --spacing7: 40px;
  --spacing8: 60px;
  --spacing9: 74px;

  --color1: #191919;
  --color2: #fff;
  --color3: #118DA8;
  --color4: #000000;
  --color5: #05667b;
  --color6: rgb(223, 45, 45);

  --h1: 24px;
  --h1-lh: 29px;

  --text-base: 18px;
  --text-regular: 16px;
  --text-accent: 14px;

  --lh-base: 26px;
  --lh-regular: 22px;
  --lh-accent: 19px;

  --borderRadius1: 4px;
  --borderRadius2: 8px;

  --transition: .2s;

  --swiper-pagination-bullet-size: 12px;

  --swiper-pagination-bullet-horizontal-gap: 6px;

  --swiper-pagination-bullet-inactive-color: #05667b;
  --swiper-pagination-color: #05667b;
  --swiper-theme-color: #05667b;
}


html,
body {
  height: 100%;
  scroll-behavior: smooth;

  scrollbar-color: var(--color3) #000; /*Цвет скролла, цвет ползунка*/
  scrollbar-width: thin; /*Толщина скролла thin, auto*/

  &::-webkit-scrollbar-track {
    background-color: #000; /*Цвет скролла*/
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px; /*Закругление ползунка*/
    background-color: var(--color3) /*Цвет ползунка*/;
  }
  &::-webkit-scrollbar {
    width: 6px; /*Толщина скролла*/
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
    background-color: var(--color3);
  }
  &::-webkit-scrollbar-thumb:horizontal {
    background: var(--color3);
    border-radius: 10px;
  }
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--text-base);
  line-height: var(--lh-base);
  color: var(--color2);
}

*, *:before, *:after {
  box-sizing: border-box;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
.h1 {
  font-size: 42px;
  line-height: 48px;
}

h2,
.h2 {
  font-size: 34px;
  line-height: 39px;
}

h3,
.h3 {
  font-size: 24px;
  line-height: 29px;
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 78px;
  background: var(--color1);
}

@media (min-width: $laptop) {
  .root {
    padding-top: 126px;
  }
  h2,
  .h2 {
    font-size: 36px;
    line-height: 43px;
  }
}


.main {
  flex: 1 1 auto;

  &__title-base {
    margin-bottom: var(--spacing5);
  }
}

.container {
  max-width: var(--cnt-width);
  width: 100%;
  margin-inline: auto;
  padding-right: var(--spacing4);
  padding-left: var(--spacing4);
}
@media (min-width: $tablet2) {
  :root {
    --cnt-width: 980px;
  }
}
@media (min-width: $desktop) {
  :root {
    --cnt-width: 1300px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: var(--transition);

  &.active {
    color: var(--color3);
  }
}
@media (min-width: $laptop) {
  a:hover {
    color: var(--color3);
  }
}

img {
  max-width: 100%;
}

button {
  padding: 0;
  outline: none;
  border: 0;
  background: 0;
  &[disabled] {
    background: gray;
    cursor: not-allowed;
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing3) var(--spacing5);
  color: var(--color2);
  font-size: var(--text-base);
  line-height: var(--lh-base);
  background: var(--color3);
  outline: none;
  border-radius: var(--borderRadius1);
  cursor: pointer;
  transition: var(--transition);
}

.button-round {
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 50%;
  &__icon {
    width: 30px;
    height: 30px;
    fill: var(--color2);
  }
}

@media (min-width: $laptop) {
  .button:hover {
    background: var(--color5);
  }
  .button[disabled]:hover {
    background: gray;
  }
  .button-round {
    width: 110px;
    height: 110px;
    &__icon {
      width: 44px;
      height: 44px;
    }
  }
}



.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: var(--spacing4) 0;


  &_active + .main {
    filter: brightness(0.2);
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_active .header__wrap-menu {
    z-index: 1;
    opacity: 1;
    pointer-events: unset;
  }
  
  &__menu {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

}

@media (max-width: $mob) {
  .header {
    
    &__wrap-menu {
      position: absolute;
      top: 76px;
      left: 0;
      z-index: -1;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: var(--spacing4);
      opacity: 0;
      transition: var(--transition);
      pointer-events: none;
    }
  }
  
}

@media (min-width: $tablet) {
  .header {

    &__button-menu {
      display: none;
    }

    &__menu {
      flex-direction: row;
      gap: var(--spacing7);

      a {
        background: var(--color4);
      }
    }

  }

}

@media (min-width: $desktop) {

  .header {
    padding: 20px 0;
  }

}





.section {
  padding: var(--spacing6) 0;
}
.section__wrap-title {
  margin-bottom: var(--spacing5);
}

@media (min-width: $desktop) {

  .section {
    padding: var(--spacing9) 0;
  }
  
}

.wrap-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing5);
}


.animated-js {
  transform: translateY(40%);
  opacity: 0;
  transition: .6s;
}

.start-anim {
  transform: translateY(0);
  opacity: 1;
}

.label {
  position: relative;

  &__text-error {
    position: absolute;
    left: 0;
    bottom: -22px;
    color: var(--color6);
  }

  &_error .label__input {
    border-color: var(--color6);
  }

}

input {
  outline: none;
  border: 0;
}
.input {
  width: 100%;
  padding: 16px 10px;
  background: rgb(43 42 42);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid transparent;
}
@media (min-width: $laptop) {
  .input {
    padding: 24px 20px;
    font-size: 21px;
    line-height: 29px;
  }
}

.form-subscribe {
  display: grid;
  gap: var(--spacing5);

  &__button {
    width: fit-content;
    min-width: 104px;
    margin-top: var(--spacing3);
  }

  &_active {
    pointer-events: none;

    .form-subscribe__button {
      font-size: 0;
      transition: none;

      &:before {
        content: 'Sending...';
        font-size: var(--text-base);
      }

    }
  }

}

svg {
  transition: var(--transition);
}

.icon-new {
  width: 30px;
  height: 30px;
  stroke: var(--color2);
}
@media (min-width: $laptop) {
  .icon-new:hover {
    stroke: var(--color3);
  }
}



.footer {
  padding: var(--spacing5) 0;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: var(--spacing6);
  }

  &__enums {
    display: flex;
    justify-content: space-between;
  }

  &__social-items {
    display: flex;
    gap: var(--spacing3);
  }

  &__menu {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: var(--spacing3);

    li {
      grid-column: 1;
    }

    li:nth-child(4) ~ li {
      grid-column: 2;
    }

    li:nth-child(5) {
      grid-row: 1;
    }

    li:nth-child(6) {
      grid-row: 2;
    }

    li:nth-child(7) {
      grid-row: 3;
    }
    
  }

}
@media (min-width: $tablet2) {

  .footer {

    &__inner {
      flex-direction: row;
      justify-content: space-between;
    }

    &__enum {
      display: flex;
      flex-direction: column;
      gap: var(--spacing3);
    }

  }  

}


.swiper {
  padding-bottom: var(--spacing8);
}