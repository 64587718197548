@import '/src/scss/vars';

.title {
  font-size: 48px;
  line-height: 59px;
  font-weight: 400;

  br {
    display: none;
  }

  &__text-decor {
    position: relative;
    
    &:after {
      content: "";
      position: absolute;
      top: auto;
      left: 0;
      width: 88px;
      height: 104px;
      background: url('./line.svg') center/contain no-repeat;
    }
  }

  &__text-accent {
    font-size: 50px;
  }

}

@media (min-width: $desktop) {
  
  .title {
    font-size: 70px;
    line-height: 88px;
    letter-spacing: 3.2px;

    &__text-decor:after {
      width: 140px;
      height: 140px;
    }

    &__text-accent {
      font-size: 80px;
    }

    br {
      display: block;
    }

  }
}