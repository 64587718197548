@import '/src/scss/vars';


.about-section {
  
  &__row {
    display: grid;
    place-items: center;
  }
  
  &__wrap-img {
    position: relative;
    width: calc(100% + var(--spacing4) * 2);
  }

  &__wrap-img:before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,.42);
  }
  
  &__img {
    display: block;
    height: calc(100vh - 78px);
    width: 100%;
    margin-inline: auto;
    object-fit: cover;
  }
  
  &__wrap-img,
  &__content {
    grid-column: 1/-1;
    grid-row: 1/-1;
  }
  
  &__content {
    position: relative;
    z-index: 1;
    max-width: 400px;
  }
  
  &__title {
    margin-bottom: var(--spacing3);
  }

  &__info {
    display: block;
    margin-top: var(--spacing3);
    color: var(--color3);
  }

  &__wrap-text {
    display: grid;
    gap: var(--spacing2);
  }


}

@media (min-width: $laptop) {

  .about-section {
    padding-top: 16px;

    &__row {
      grid-template-columns: 1fr 650px;
      gap: var(--spacing8);
    }

    &__wrap-img,
    &__content {
      grid-column: unset;
      grid-row: unset;
    }

    &__wrap-img {
      width: auto;
    }

    &__wrap-img:before {
      display: none;
    }

    &__img {
      height: auto;
    }

    &__content {
      max-width: unset;
    }

    &__info {
      margin-top: var(--spacing5);
    }

    &__title {
      margin-bottom: var(--spacing6);
    }

    &__wrap-text {
      gap: var(--spacing5);
    }

  }

}