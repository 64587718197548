@import '/src/scss/vars';

.box {
  display: flex;
  flex-direction: column;
  gap: var(--spacing3);
  padding: 36px;
  border-radius: 32px;
  background: var(--color4);
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
}

.name {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #fff;
  opacity: 0.7;
}

.rating {
  display: flex;
  gap: 8px;
}

@media (min-width: $mob) {

  .text {
    font-size: 36px;
    line-height: 43.2px;
  }

  .name {
    font-size: 24px;
    line-height: 28px;
  }

}